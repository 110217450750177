export const ADDRESSES_TYPES = {
  UR: "Юридический",
  FACT: "Фактический",
};

export const ADDRESSES_TYPES_INT = {
  UR: 0,
  FACT: 1,
};


export const DEFAULT_VALUE = {
  typeId: "",
  name: "",
  ogrnNumber: "",
  innNumber: "",
  kppCode: "",
  okpoCode: "",
  okvedCode: "",
  file: "",
  licenseNumber: "",
  licenseExpirationDate: null,
  bankDetails: {
    bankName: "",
    account: "",
    correspondentAccount: "",
    bankBIC: "",
  },
  organizationAddresses: [
    // mock data
    {
      address: "",
      type: ADDRESSES_TYPES.UR,
    },
    {
      address: "",
      type: ADDRESSES_TYPES.FACT,
    },
  ],
  organizationResponsibleBankDetails: {
    bankName: "",
    account: "",
    correspondentAccount: "",
    bankBIC: "",
  },

  organizationResponsibleAddresses: [
    // mock data
    {
      address: "",
      type: ADDRESSES_TYPES.UR,
    },
    {
      address: "",
      type: ADDRESSES_TYPES.FACT,
    },
  ],

  organizationResponsibleContracts: [
    {
      organization: null,
      equipmentKind: null,
      contractNumber: "",
      expirationDate: "",
      contractFile: {
        filesPrev: [],
        filesToAdd: [],
        filesToDelete: [],
      },
    },
  ],
};

export const YANDEX_MAPS_DATA = {
  query: {
    apikey: process.env.REACT_APP_API_KEY,
    suggestapikey:"3c1fb831-3401-4718-a303-c4d0dfb2b10d",
    load: 'SuggestView,geocode',
    lang: "ru_RU",
  },
  defaultState: {
    center: [55.68, 37.73],
    zoom: 7,
    controls: [],
    type: "yandex#map",
  },
  modules: [
    "geocode",
    "SuggestView",
    "geolocation",
    "borders",
    "ObjectManager",
    "clusterer.addon.balloon",
  ],
  defaultOptions: {
    minZoom: 3,
    scrollZoomSpeed: 2,
    suppressMapOpenBlock: true,
  },
};

export const fields = {
  organization_customer: "organization_customer",
  organization_contractor: "organization_contractor",
  outside_organization: "outside_organization",
/*   control_and_executive_organization: "control_and_executive_organization", */
};

export const TYPE_ID = {
  [fields.organization_customer]: 1,
  [fields.organization_contractor]: 2,
  [fields.outside_organization]: 3,
 /*  [fields.control_and_executive_organization]: 4, */
};
