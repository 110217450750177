import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import { useForm} from "react-hook-form";
import { useNavigate } from "react-router";
import {
  useAddTaskMutation,
} from "../../../../middleware/tasksApi";
import { formFields } from "./options";
import { useAction } from "../../../../hooks/actions";
import { PATHS } from "../../../../constants";
import { formConfigSection, sections } from "./form-config";
import { getConfigByRole, getRequestData, getRequestDataFilesForAdd } from "./helpers";
import { useAppSelector } from "../../../../hooks/redux";
import { WarningValidationForm } from "../../../components/Notifications";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import FormItem from "../../../components/FormItem";
import AccordionSection from "./AccordionSection";
import { DEFAULT_ROLE_ID } from "../../../../constants";
import { fields, PRIORITY_ID } from "../constants";
import {
  useGetFacilitySelectElementsQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery,
} from "../../../../middleware/facilitiesApi";
import dayjs from "dayjs";

const AddTask = () => {
  const [ranOnce, setRanOnce] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    watch,
    trigger,
    resetField,
  } = useForm({
    mode: "onSubmit",
  });

//Get Object and Floor
  const facilityId = watch(formFields.ProtectionObjectId);
  const floor = watch(formFields.FloorInfoId);
  const { errorFace } = useGetFacilitySelectElementsQuery();
  const [getFloorOptions, { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions }] =
    useLazyGetFloorOptionsSelectByFacilityQuery();
  const isErrorRequest = errorFace || isErrorGetFloorOptions;
  
    useEffect(() => {
      facilityId &&
        getFloorOptions(facilityId).then(
          ({ data, isSuccess }) =>
            isSuccess &&
            !!data?.length &&
            setValue(formFields.FloorInfoId, data[0].value)
        );
    }, [facilityId, getFloorOptions, setValue]);


  const isActive = true; // mock data. Wait from backend
  const { info } = useAppSelector((state) => state.users);
  const { roleId} = info || {};
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [objectId, setObjectId] = useState("");
  const [regulations, setRegulations] = useState([]);
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
  useManipulateFilesMutation();
  const [addTask, { error: errorAdd, isSuccess: isSuccessAdd }] =
    useAddTaskMutation();
  
  const configForm = useMemo(
    () =>
      formConfigSection(
        isActive,
        regulations,

        objectId,
        setValue,
        resetField
      ),
    [isActive, regulations, objectId, setValue, resetField]
  );
  const isWarningValidation = !!Object.keys(errors).length;
  const errorSubmit = errorAdd || errorFile;
  const isSuccess = isSuccessAdd || isSuccessFile;
  const handleCancel = () => navigate(PATHS.LIST_TASKS);;
  useEffect(() => {
    if (!ranOnce) {
    
      setRanOnce(true);
      setValue(formFields.priority,PRIORITY_ID[fields.lower])
      setValue(formFields.deadline, dayjs().add(10, 'day'))
    }
  }, []);
  
  useEffect(() => {
    setIsErrorSubmit(errorSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSubmit]);
  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  

  // Обработка события отправки формы
  const onSubmit = async (data) => {
    const { entities, jobFiles } = getRequestData(data);
    console.log("entities", entities)
    addTask(entities).then((result) => {
      if ("error" in result) {
        return;
      }
      const id = result?.data?.id;
      console.log("ID",id)
      const requestDataFiles = getRequestDataFilesForAdd(id, jobFiles);

      manipulateFiles(requestDataFiles).then((response) => {
        if ("error" in response) {
          return;
        }
        
      });
      navigate(PATHS.LIST_TASKS);
    })    
  };

  return (
    <Box className="template_page employee">
      <Box className="template_page-header">
        <Typography className="template_page-header-title">
          {t("title_page:add_task")}
        </Typography>
        <Box className="template_page-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="myform"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSubmitSuccessful && !errorSubmit}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>

      <form  id="myform"
        onSubmit={handleSubmit(onSubmit)} className="scrolled-form">
          <Box sx={{ paddingBottom: "16px" }}>
        {configForm &&
          configForm?.map(
            ({ id,
              name,
              label,
              input,
              expended,
              isCanDisabled,
              CustomFormItem,
              ...rest }) =>
              <Box
                key={name}
                sx={{
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <FormItem
                  
                  control={control}
                  errors={errors}
                  name={name}
                  disabled={isCanDisabled ? !facilityId || isErrorRequest : false}
                  label={label}
                  input={input}
                  trigger={trigger}
                  {...rest}
                />
              </Box>
          )}
          </Box>
        {sections({ control }).map(
          ({ id, title, hidden, Component, config }) =>
            !hidden && (
              <AccordionSection key={id} title={title}>
                {Component && (
                  <Component
                    id={id}
                    control={control}
                    errors={errors}
                    facilityId={facilityId}
                    config={getConfigByRole({
                      roleId: roleId || DEFAULT_ROLE_ID,
                      config,
                    })}
                  />
                )}
              </AccordionSection>
            )
        )}


      </form>
      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};

export default AddTask;
