import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import BasicInformation from "./BasicInformation";
import { ContactsForm } from "./ContactsForm";
import { PATHS } from "../../../constants";
import { DEFAULT_VALUE } from "./constants";
import { formatPhoneNumber } from "../ResponsibleOrganizationsList/helpers";
import { useRegistrateNewUserMutation } from "../../../middleware/usersApi";
import { useEffect } from "react";
import { useAction } from "../../../hooks/actions";
import { WarningValidationForm } from "../../components/Notifications";
import { Dogovor1 } from "./Dogovor1";
import { Dogovor2 } from "./Dogovor2";
import { Dogovor3 } from "./Dogovor3";
import { useWatchOrganizationInnNumber, useWatchBicNumber } from "./hooks";

export const RegistrationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registered, setRegistered] = useState(false);
  const [openDogovor1, setOpenDogovor1] = useState(false);
  const [openDogovor2, setOpenDogovor2] = useState(false);
  const [openDogovor3, setOpenDogovor3] = useState(false);
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    resetField,  
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUE,
  });

  const checkedConfirm = watch("confirm");
  const { innNumberValue, shouldDisableAllFields, okvedCode, indCheck, addressFromDadata, setaddressFromDadata, resAddressFromDadata, setresAddressFromDadata } = useWatchOrganizationInnNumber({
    errors,
    control,
    reset,
    setValue,
    resetField
  });
  const { BankInfo } = useWatchBicNumber({
    errors,
    control,
    setValue,
    
  });
  const [registrateUser, { error: errorAdd, isSuccess: isSuccessAdd }] =
    useRegistrateNewUserMutation();

  const isWarningValidation = !!Object.keys(errors).length;

  const onSubmit = (data) => {
    const { organizationAddresses = [], ...rest } =
      data?.organizationRegistrationInfo;
    const formattedOrganizationAddresses = organizationAddresses
      .map(({ type, extension },index) => ({
        type: type === undefined ? index : type,
        extension,
        city: addressFromDadata[index].data?.city,
        street: addressFromDadata[index].data?.street,
        building: addressFromDadata[index].data?.house,
      }));

    const requestData = {
      name: data?.fullName,
      email: data?.email,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      organizationRegistrationInfo: {
        ...rest,
        organizationAddresses: formattedOrganizationAddresses,
      },
    };

    registrateUser(requestData).then(() =>
      setRegistered(!registered)
    );
  };

  useEffect(() => {
    setIsErrorSubmit(errorAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAdd]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd]);

  return (
    <Box className="legal-entity registration_field" sx={{ margin: "2% 0 " }}>
      {registered ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30%",
            gap: "30px",
          }}
        >
          <Typography className="title-block">
            Ваша заявка успешно зарегистрирована!
          </Typography>
          <Typography>
            Доступ к личному аккаунту Вы получите на указанный Вами адрес
            электронной почты.{" "}
          </Typography>
          <Button
            className="btn btn-link"
            onClick={() => navigate(PATHS.LOGIN_ROUTE)}
          >
            Перейти к авторизации
          </Button>
        </Box>
      ) : (
        <>
          <Box className="legal-entity-header">
            <Typography
              className="login-title-auth"
              sx={{ mb: "14px" }}
              variant={"h4"}
            >
              {t("title_page:registration")}
            </Typography>
            <Box className="legal-entity-header-buttons">
              <Button
                form="registrationForm"
                className="btn btn-primary-fill"
                type="submit"
                disabled={!checkedConfirm}
              >
                {t("button:submit_an_application")}
              </Button>
            </Box>
          </Box>

          <form
            className="registration-scrolled-form"
            id="registrationForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <BasicInformation control={control}
             indCheck={indCheck}
            addressFromDadata={addressFromDadata}
              setaddressFromDadata={setaddressFromDadata}  errors={errors} />
            <ContactsForm control={control} errors={errors} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "15px",
                margin: "25px 0",
              }}
            >
              <Button
                className="btn btn-link"
                onClick={setOpenDogovor1.bind(null, true)}
                sx={{ textAlign: "start", lineHeight: "20px" }}
              >
                {t("button:agree_processing_data")}
              </Button>
              <Button
                className="btn btn-link"
                onClick={setOpenDogovor2.bind(null, true)}
                sx={{ textAlign: "start", lineHeight: "20px" }}
              >
                {t("button:privacy_policy")}
              </Button>
              <Button
                className="btn btn-link"
                onClick={setOpenDogovor3.bind(null, true)}
                sx={{ textAlign: "start", lineHeight: "20px" }}
              >
                {t("button:agree_use_app")}
              </Button>
            </Box>

            <Box className="confirm_conditions">
              <Controller
                name="confirm"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label={t("label:confirm_conditions")}
                  />
                )}
              />
            </Box>
          </form>

          {isWarningValidation && (
            <WarningValidationForm isWarning={isWarningValidation} />
          )}
        </>
      )}
      {openDogovor1 && (
        <Dogovor1 open={openDogovor1} onClose={setOpenDogovor1.bind(null, false)} />
      )}
      {openDogovor2 && (
        <Dogovor2 open={openDogovor2} onClose={setOpenDogovor2.bind(null, false)} />
      )}
      {openDogovor3 && (
        <Dogovor3 open={openDogovor3} onClose={setOpenDogovor3.bind(null, false)} />
      )}
    </Box>
  );
};
