import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Box";

import ButtonsField from "../ButtonsField";
import { formFields } from "../form-config";
import { PATHS } from "../../../../constants";
import {
  getRequestNewData,
  getRequestDataFilesForAdd,
  convertToValuesArray,
  getPositiveKeys,
  updateValuesWithFilter,
} from "../helpers";
import { useAction } from "../../../../hooks/actions";
import {
  useAddFacilityMutation,
  useDeleteFacilityMutation,
} from "../../../../middleware/facilitiesApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useGetOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import { timezones } from "../options";

import { BasicTabs } from "../BasicTabs";
import { WarningValidationForm } from "../../../components/Notifications";
import { useGetEquipmentSystemsQuery } from "../../../../middleware/equipmentsApi";
import { INIT_VALUE_TAB } from "../constants";

export const AddObjectTabsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [value, setValue] = useState(INIT_VALUE_TAB);
  const handleStep = (step) => {
    setValue(step);
  };

  const [yurFace, setyurFace] = useState([]);
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [addFacility, { error: errorAdd, isSuccess: isSuccessAdd }] =
    useAddFacilityMutation();
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();
  const [deleteFacility] = useDeleteFacilityMutation();

  const { data: EquipmentSystems = [] } = useGetEquipmentSystemsQuery("");

  const isError = errorAdd || errorFile;
  const isSuccess = isSuccessAdd && isSuccessFile;

  const localGmtTimeZoneHours = (new Date().getTimezoneOffset() / 60) * -1;
  const defaultGmtTimeZone = timezones.find(
    ({ value }) => localGmtTimeZoneHours === value
  );

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.buildingPassport]: {
        gmtTimeZone: defaultGmtTimeZone?.value || null,
      },
      [formFields.buildingCharacteristics]: {
        [formFields.fireCompartmentsCount]: 1,
      },
    },
  });
  const [isValid, setIsValid] = useState(methods.formState.isValid);
  const isWarningValidation =
    !!Object.keys(methods.formState.errors).length || (!isValid && value !== INIT_VALUE_TAB);
  useEffect(() => {
    value === INIT_VALUE_TAB && setIsValid(methods.formState.isValid);
  }, [value, methods.formState.isValid]);

  var facility_id_var = null;
  var fireCompartments_var = null;

  const onSubmit = (data) => {

    const { entities, files } = getRequestNewData(data);
    console.log("data",files)
      
      const values = convertToValuesArray(EquipmentSystems);

      const filterFacilitySystems = getPositiveKeys(entities?.facilitySystems);
  
      const updatedValues = updateValuesWithFilter(values, filterFacilitySystems);
  
      const newData = {
        ...entities,
        facilitySystemsAvailabilites: updatedValues,
        fireCompartments: entities?.buildingCharacteristics?.fireCompartments,
      };

      if (facility_id_var != null && fireCompartments_var != null)
      {
        const requestDataFiles = getRequestDataFilesForAdd(
          facility_id_var,
          fireCompartments_var,
          files
        );

        manipulateFiles(requestDataFiles).then((response) => {
          if ("error" in response) {
          } else {
            navigate(PATHS.LIST_OBJECTS);
          }
        });
        return;
      }

     addFacility(newData).then((result) => {
      if (!("error" in result)) {
        facility_id_var = result?.data?.buildingPassport.id;
        fireCompartments_var = result?.data?.fireCompartments;
        const requestDataFiles = getRequestDataFilesForAdd(
          facility_id_var,
          fireCompartments_var,
          files
        );
      manipulateFiles(requestDataFiles).then((response) => {
          if ("error" in response) {
          } else {
            navigate(PATHS.LIST_OBJECTS);
          }
        });
      } else {
        //navigate(PATHS.LIST_OBJECTS);
      }
    });
  };

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useGetOrganizationsSelectQuery();

  return (
    <Box className="template_page object">
      <Box className="template_page-header">
        <Typography className="template_page-header-title">
          {t("title_page:add_object")}
        </Typography>
        <ButtonsField
          handleCancel={handleCancel}
          handleSubmit={methods.handleSubmit(onSubmit)}
          isSubmitDisabled={!isValid && value !== INIT_VALUE_TAB}
          value={value}
          handleStep={handleStep}
        />
      </Box>
      <FormProvider {...methods}>
        <form
          id="myform"
          className="object-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <BasicTabs
            equipmentSystems={EquipmentSystems}
            handleSubmit={methods.handleSubmit(onSubmit)}
            methods={methods}
            value={value}
            setValue={setValue}
          />
        </form>
      </FormProvider>

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};
