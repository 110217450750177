import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import "./styles/style.css";

import { config, formFields } from "./form-config";
import { useAction } from "../../../hooks/actions";
import {
  useGetFacilitySelectElementsWithoutCacheQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery,
  useLazyGetFacilitySelectElementsWithSearchQuery,
  useLazyGetFacIntNumbersQuery
} from "../../../middleware/facilitiesApi";
import {
  useLazyGetEquipmentsCoordinatesQuery,
  useUpdateEquipmentsCoordinatesMutation,
  useUpdateRoomAddressesCoordinatesMutation,
  useGetEquipmentsCoordinatesPostMutation,
  useLazyGetRoomsColorsQuery,
  
} from "../../../middleware/equipmentsApi";
import {
  getEquipmentsCoordinatesWithRoomId,
  getRoomAddressForRequest,
  getRoomAddressForScheme
} from "../../../helpers/helper";
import { useAppSelector } from "../../../hooks/redux";
import FormItem from "../../components/FormItem";
import SelectSimple from "../../components/SelectSimple";
import { ImageSchema } from "./ImageSchema";
import { StagingTO } from "./ImageSchema/StagingTO";
import PoligonIcon from "../../components/icons/PoligonIcon";
import Editpen from "../../components/icons/Editpen";
import ArrowCancelBack from "../../components/icons/ArrowCancelBack";
import ArrowPrevNext from "../../components/icons/ArrowPrevNext";
import CloseRedIconRemove from "../../components/icons/CloseRedIconRemove";
import { ModalTableSetOnTo } from "../Acts/ModalTableSetOnTo";
import { useLazyGetListEventMessagesQuery } from "../../../middleware/logsApi";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const Plume = () => {
  let intervalId;
  const stageRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );
  
  let intervalplume = "";
  const {
    clearFloors,
    setIsErrorSubmit,
    setIsSuccessSubmit,
    setIsErrorRequest,
    setErrorNotificationParams,
  } = useAction();
  const [
    getFloorOptions,
    { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions },
  ] = useLazyGetFloorOptionsSelectByFacilityQuery();
  const [
    getFacilitySelectElems,
    { isError: isErrorgetFacilitySelectElems, isLoading: isLoadinggetFacilitySelectElems },
  ] = useLazyGetFacilitySelectElementsWithSearchQuery();
  
  const [
    getfacIntNum,
    { isError: isErrorgetfacIntNum, isLoading: isLoadinggetfacIntNum },
  ] = useLazyGetFacIntNumbersQuery();
  const [getEquipmentsCoordinates, { isLoading: isLoadingGetEquipments }] =
    useLazyGetEquipmentsCoordinatesQuery();
  const [
    updateCoordinates,
    { isError: isErrorUpdate, isSuccess: isSuccessUpdate },
  ] = useUpdateEquipmentsCoordinatesMutation();

  const [
    updateRoomAddressesCoordinates,
    {
      isError: isErrorUpdateRoom,
      isSuccess: isSuccessUpdateRoom,
      error: errorUpdateRoom,
    },
  ] = useUpdateRoomAddressesCoordinatesMutation();
  const [
    getEquipmentsCoordinatesPost,
    {
      isError: isErrorCoordinatesPost,
      isSuccess: isSuccessCoordinatesPost,
      error: errorCoordinatesPost,
    },
  ] = useGetEquipmentsCoordinatesPostMutation();
  
  //const { data: facilitySelectElements, error: error } = useGetFacilitySelectElementsWithoutCacheQuery();
  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
    setValue,
    getValues,
    watch,
    resetField,
    reset,
  } = useForm();
  const $templatePage = useRef(null);
  const [floorScheme, setFloorScheme] = useState("");
  const [runOnce, setrunOnce] = useState(true);
  const [searchIntNum, setsearchIntNum] = useState("");
  const [searchObject, setsearchObject] = useState("");
  const [isChangefloorScheme, setisChangefloorScheme] = useState("");
  const [turnOnEdit, setTurnOnEdit] = useState(false);
  const [xyGroup, setxyGroup] = useState([]);
  const [addplume, setaddplume] = useState(false);
  const [isDrawPoligon, setIsDrawPoligon] = useState(false);
  const [isEditShape, setisEditShape] = useState(true);
  const [openModalSetObject, setOpenModalSetObject] = useState(false);
  const [isListClick, setisListClick] = useState(false);
  const [lineColor, setLineColor] = useState("");
  const [stagingTO, setstagingTO] = useState(true);
  const [StartTO, setStartTO] = useState(true);
  const [minScale, setminScale] = useState(0.5);
  const [zoomOut, setzoomOut] = useState(false);
  const [zoomIn, setzoomIn] = useState(false);
  const [scaleEditRoom, setscaleEditRoom] = useState(1);
  const [isProtectionObjectIsInTest, setisProtectionObjectIsInTest] = useState(false);
  const [openEventSide, setopenEventSide] = useState(true);
  const [messageFloor, setmessageFloor] = useState(null);
  const [isDragabble, setisDragabble] = useState(false);
  const [showHeader, setshowHeader] = useState(false);
  const [newRoomAddresses, setNewRoomAddresses] = useState([]);
  const [roomsColors, setroomsColors] = useState([]);
  const [removedAddress, setRemovedAddress] = useState([]);
  const [changeRoomAddresses, setchangeRoomAddresses] = useState([]);
  const [deletedAddress, setdeletedAddress] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [scale, setScale] = useState(1);
  const [stageposition, setstageposition] = useState({ x: 0, y: 0 });
  const [selectedListItem, setselectedListItem] = useState(null);
  const [isselectedEvent, setisselectedEvent] = useState(false);
  const [isChangeFloor, setisChangeFloor] = useState(true);
  const [logsMessagess, setlogsMessages] = useState(null);
  const isErrorRequest =  isErrorGetFloorOptions || isErrorgetfacIntNum;
  const isErrorSubmit = isErrorUpdate || isErrorUpdateRoom;
  const isSuccessSubmit = isSuccessUpdate || isSuccessUpdateRoom;
  const isLoading = isLoadingGetEquipments || isLoadingGetFloorOptions;
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [blinkingItem, setBlinkingItem] = useState(null);
  const [intervals, setIntervals] = useState({});
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const facilityId = watch(formFields.object);
  const floor = watch(formFields.floor);
  const roomAddresses = watch(formFields.roomAddresses) || [];

  const [GetListEventMessages] =
  useLazyGetListEventMessagesQuery();
  const [GetRoomsColors] =
    useLazyGetRoomsColorsQuery();  
  const loadScheme = (ss, sx, sy, withEquip,ischangeFloor) => {

    getEquipmentsCoordinates(`id=${floor}&returnEquipments=${withEquip}&returnRooms=${true}`).then(({ data, isSuccess }) => {
      if (isSuccess && !data?.length) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          data || {};
        setFloorScheme(floorSchemeFileLink);
       // stopBlinking(selectedListItem?.id);
       
       if(ischangeFloor) setchangeRoomAddresses(roomAddresses)
       // setselectedListItem(null)
        setisProtectionObjectIsInTest(false)
      
        setValue(formFields.roomAddresses, roomAddresses);
        setValue(formFields.equipmentsCoordinates, []);
        if (sx == 0 && sy == 0)
          setstageposition({ x: 1, y: 1 })
        if (ss) setScale(1)
        setIsDrawPoligon(false);
        setzoomOut(false)
        setNewRoomAddresses([]);
        setRemovedAddress([]);
      }
    });
  }

  const onSubmit = (data) => {
    const {
      floor: floorInfoId,
      roomAddresses,
      equipmentsCoordinates,
    } = data || {};
    
    const newArray = roomAddresses.map(item => {
      item.protectionObjectIntegrNumberAltId = item.protectionObjectIntegrNumberAltId == '' ? null : item.protectionObjectIntegrNumberAltId;
      item.plumes = typeof item.plumes[0] === 'string' ? item.plumes : item.plumes.map(el => el.name);
      const foundItem = xyGroup.find(i => i.id === item.id);
      if (foundItem) {
        return {
          ...item, coordinates: item.coordinates.sort((a, b) => a.order - b.order).map((res, index) => {
            return { ...res, coordinateX: ((res.coordinateX * foundItem.scaleX + (foundItem.x / foundItem.scaleRoom.x))), coordinateY: ((res.coordinateY * foundItem.scaleY + (foundItem.y / foundItem.scaleRoom.y))) }
          })
        };
      } else {
        return item;
      }
    });

   
    updateRoomAddressesCoordinates({ floorInfoId,roomAddressesIdsToDelete:deletedAddress?.map(obj => obj.id), roomAddresses: newArray }).then(
      ({ data, error }) => {
        const requestData = getEquipmentsCoordinatesWithRoomId({
          equipmentsCoordinates,
          rooms: data,
        });

        if (!error) {
          const data = requestData.map(
            ({
              id,
              floorSchemeCoordinateX,
              floorSchemeCoordinateY,
              roomAddressId,
              floorInfoId,
              typeId,
            }) => ({
              id,
              floorSchemeCoordinateX,
              floorSchemeCoordinateY,
              roomAddressId,
              floorInfoId,
              typeId,
            })
          );
          data?.length && updateCoordinates(data);
        }

        // reset();
        setFloorScheme("");
        setTurnOnEdit(false);
        setaddplume(false);
        setstagingTO(true)
        setdeletedAddress([])
        setselectedListItem(null)
        setxyGroup([])
        setshowHeader(false)
        setisDragabble(false);
        setIsDrawPoligon(false);
        setNewRoomAddresses([]);
        setRemovedAddress([]);
        loadScheme(1, 0, 0, false,false)
      }
    );
  };
  
    
  const handleAddPlume = () => {
    setshowHeader(true)
    loadScheme(1, 0, 0, false,false)
    setstagingTO(false)
    setisDragabble(false)
    stopBlinking(selectedListItem?.id);
    setaddplume(true);
    setStartTO(false)
  };
  const handleEditPlume = () => {
    setshowHeader(true)
    selectShape(null)
    loadScheme(1, 0, 0, false,false)
    setstagingTO(false)
    setisDragabble(false)
    setTurnOnEdit(true);
    stopBlinking(selectedListItem?.id);
    setStartTO(false)
  };
  const handlestagingTO = () => {
    setOpenModalSetObject(true)
  };
  useEffect(()=>{
    getFacilitySelectElems({name:searchObject}).then(
      ({ data, isSuccess }) => {
        if (!!data?.length) {
          if (runOnce) {
            if (localStorage.getItem("currentPlan")) {
              const parsedCurrPlan = JSON.parse(localStorage.getItem("currentPlan"))
              setValue(formFields.object, parsedCurrPlan.object)
              setmessageFloor(parsedCurrPlan.floor)
            } else {
              setValue(formFields.object, "все")
            }
            if (logsMessagess == null) {
              const parsedCurrPlan = JSON.parse(localStorage.getItem("currentPlan"))
              GetListEventMessages({FloorInfoId: parsedCurrPlan ? `&FloorInfoId=${getValues(parsedCurrPlan.floor)}` : getValues(formFields.floor) ? `&FloorInfoId=${getValues(formFields.floor)}` : ""}).then(({ data, isSuccess }) => {
                setlogsMessages(data);
              })
        
            }
            setrunOnce(false)
          }

        }
      }
    );
  },[searchObject])
  
  useEffect(()=>{
    if (facilityId) {
    getfacIntNum({protectionObjectId:facilityId,number:searchIntNum})
    }
  },[searchIntNum])

  useEffect(() => {
   
    if (StartTO) {
      intervalId = setInterval(() => {
        if(getValues(formFields.object) != "все" && getValues(formFields.floor)){
        GetListEventMessages({FloorInfoId:`&FloorInfoId=${getValues(formFields.floor)}` || ""}).then(({ data, isSuccess }) => {
          setlogsMessages(data);
        })
        GetRoomsColors({floorId:`?floorId=${getValues(formFields.floor)}`}).then((data)=>{
  
          setroomsColors(data.data)
        });
      }else{
        GetListEventMessages({FloorInfoId:""}).then(({ data, isSuccess }) => {
          setlogsMessages(data);
        })
        GetRoomsColors({floorId:""}).then((data)=>{
  
          setroomsColors(data.data)
        });
      }
      }, 10000);

      return () => clearInterval(intervalId);
    } else {
      clearInterval(intervalId)
    }
  }, [StartTO, GetListEventMessages, setlogsMessages]);

  const handleTO = () => {
    setStartTO(prevState => !prevState)
  };

  const handleBackToManageEquipments = () => {
    setTurnOnEdit(false);
    setaddplume(false);
    selectShape(null)
    setisDragabble(true)
    const prevRoomAddresses = roomAddresses?.filter(({ id }) => id);
    const newRoomAddressesRequest = getRoomAddressForRequest(newRoomAddresses);
    const addresses = prevRoomAddresses
      ? [...prevRoomAddresses, ...newRoomAddressesRequest]
      : newRoomAddressesRequest;
    setValue(formFields.roomAddresses, addresses);
    getEquipmentsCoordinates(`id=${floor}&returnEquipments=${true}&returnRooms=${true}`).then(({ data, isSuccess }) => {
      if (isSuccess && !data?.length) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          data || {};
        const coordinates = equipmentCoordinates.map((item) => ({
          ...item,
          floorInfoId: floor,
        }));
        setValue(formFields.equipmentsCoordinates, coordinates)

        const scaleStage = 1.5;

      }
    });
  };

  const handleArrowBack = () => {
    setRemovedAddress((prev) => [...prev, ...newRoomAddresses.slice(-1)]);
    setNewRoomAddresses((prev) => prev.slice(0, -1));
  };

  const handleArrowForward = () => {
    setNewRoomAddresses((prev) => [...prev, ...removedAddress.slice(-1)]);
    setRemovedAddress((prev) => prev.slice(0, -1));
  };
  const isSelectedSelect = (itemm) =>{
    let selectedItem = itemm ? itemm : selectedListItem
    window.innerWidth <= 600 && setopenEventSide(false)
    //setselectedListItem((prev) => itemm)
    loadScheme(1, 0, 0, false,true)
    setisselectedEvent(false)
   /* getEquipmentsCoordinatesPost({id:selectedItem?.floorInfoId,equipmentIds:selectedItem.equipmentsIds,roomAddressId:selectedItem.roomAddressId,returnEquipments:true}).then(({ data, isSuccess }) => {
      console.log("SSDD",data, isSuccess)
      if (!data?.length) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          data || {};

        setFloorScheme(floorSchemeFileLink);
        const coordinates = equipmentCoordinates.map((item) => ({
          ...item,
          floorInfoId: selectedItem?.floorInfoId,
        }));
        setValue(formFields.equipmentsCoordinates, coordinates);
        setValue(formFields.roomAddresses, roomAddresses);
        setStartTO(false)
        setIsDrawPoligon(false);
        setNewRoomAddresses([]);
        setRemovedAddress([]);
        
        /* if (floorSchemeFileLink != floorScheme) {
           setisChangefloorScheme(true)
           setisListClick(true)
         } else {
           setisChangefloorScheme(false)
 
           setstageposition({ x: 0, y: 0 });
           const scaleAdresses = getRoomAddressForScheme(roomAddresses, scaleCanvas).filter((item) => item.id == itemm.roomAddressId)[0]?.coordinates
           const scaleAdressess = roomAddresses.filter((item) => item.id == itemm.roomAddressId)[0].coordinates
           const scalecoordinates = scaleAdressess.filter((item) => item.order == 0)[0]
           const oddIndexValues = scaleAdresses.filter((_, index) => index % 2 !== 0);  // Нечетные по индексу элементы
           const evenIndexValues = scaleAdresses.filter((_, index) => index % 2 === 0);
           const minEvenIndex = Math.min(...evenIndexValues);  // Минимальное значение четных по индексу элементов
           const maxEvenIndex = Math.max(...evenIndexValues);  // Максимальное значение четных по индексу элементов
           const minOddIndex = Math.min(...oddIndexValues);  // Минимальное значение нечетных по индексу элементов
           const maxOddIndex = (Math.max(...oddIndexValues));
           const diffX = (maxEvenIndex - minEvenIndex)
           const diffY = (maxOddIndex - minOddIndex)
 
           let scaleStageX = 2;
           let scaleStageY = 2;
           let scaleStage = 2;
           let coorX = 0;
           let coorY = 0;
           console.log("NEABOBA", scaleStage)
           scaleStageY = (((height - 10) / (maxOddIndex - minOddIndex)));
           scaleStageX = ((width - 10) / (maxEvenIndex - minEvenIndex));
           if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= height / scaleStageY) || (diffX <= diffY && width < height)) {
             scaleStage = scaleStageX
           } else {
             scaleStage = scaleStageY
           }
           const diffWidth = (width - (diffX * scaleStage)) / 2
           const diffHeight = (height - (diffY * scaleStage)) / 2
           if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= height / scaleStageY) || (diffX <= diffY && width < height)) {
             coorX = (minEvenIndex - (diffWidth / scaleStage)) / scaleCanvas.x
             coorY = (minOddIndex - (diffHeight / scaleStage)) / scaleCanvas.x
           } else {
             coorX = (minEvenIndex - (diffWidth / scaleStage)) / scaleCanvas.x
             coorY = (minOddIndex - (diffHeight / scaleStage)) / scaleCanvas.x
           }
           setScale(scaleStage)
           const offsetX = coorX * scaleStage * scaleCanvas.y
           const offsetY = coorY * scaleStage * scaleCanvas.y
           setstageposition((prev) => ({ x: 0 - offsetX, y: 0 - offsetY }));
 
         }
 
      }
    });*/
  }
  useEffect(() => {
    if(facilityId){
      console.log("facilityId",facilityId)
      if(facilityId != "все" && facilityId){
        getFloorOptions(facilityId).then(
          ({ data, isSuccess }) => {
            if (!!data?.length) {
              setValue(formFields.floor, messageFloor ? messageFloor : data[0].value)
              GetRoomsColors({floorId:`?floorId=${getValues(formFields.floor)}`}).then((data)=>{
              
                setroomsColors(data.data)
              });
              setmessageFloor(null)
            } 
          }
        );
        getfacIntNum({protectionObjectId:facilityId,number:searchIntNum})
      }else{
        clearFloors()
        GetListEventMessages({FloorInfoId:""}).then(({ data, isSuccess }) => {
          setlogsMessages(data);
        })
        GetRoomsColors({floorId:""}).then((data)=>{
  
          setroomsColors(data.data)
        });
      }   
    }
      
  }, [facilityId, getFloorOptions, setValue]);

  useEffect(() => {
    if (floor) {
      if(isselectedEvent) {
        if (blinkingItem !== null && blinkingItem !== selectedListItem?.id) {
          // Clear the previous blinking interval and effect
          setLineColor("")
          clearInterval(intervals[blinkingItem]);
        }
    
        if (blinkingItem !== selectedListItem?.id) {
          startBlinking(selectedListItem);
        } else {
          stopBlinking(selectedListItem?.id);
        }
        isSelectedSelect()
      }
      else {
        
        setisChangeFloor(true)
        setstageposition({x:1,y:1})
        loadScheme(1, 0, 0, false,true)
        
      }
      
      localStorage.setItem("currentPlan", JSON.stringify({ object: facilityId, floor: floor }))
      GetListEventMessages({FloorInfoId:facilityId != "все" ?`&FloorInfoId=${getValues(formFields.floor)}` : ""}).then(({ data, isSuccess }) => {
        setlogsMessages(data);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor]);

  /*const loadSchemeWithScale = (e) => {
    getEquipmentsCoordinates(`id=${floor}&returnEquipments=${false}&returnRooms=${true}`).then(({ data, isSuccess }) => {
      if (isSuccess && !data?.length) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          data || {};
        setFloorScheme(floorSchemeFileLink);
        stopBlinking(selectedListItem?.id);

        setselectedListItem(null)
        setisProtectionObjectIsInTest(false)
        setValue(formFields.equipmentsCoordinates, []);
        setValue(formFields.roomAddresses, roomAddresses);
        let minDifferenceY = null;
        let maxDifferenceY = null;
        let maxDifference = 0;
        const adresses = getRoomAddressForScheme(roomAddresses,1)
        for (let i = 0; i < adresses.length; i++) {
          let coordinates = adresses[i].coordinates;
          let maxY = Math.max(...coordinates.filter((_, index) => index % 2 !== 0));
          let minY = Math.min(...coordinates.filter((_, index) => index % 2 !== 0));
          let difference = maxY - minY;

          if (difference > maxDifference) {
            maxDifference = difference;
            minDifferenceY = minY
            maxDifferenceY = maxY
          }
        }
        let scaleStageY = (((height - 10) / (maxDifferenceY - minDifferenceY)));
        setstageposition({ x: 0, y: 0 })
        setScale(1)
        setIsDrawPoligon(false);
        setzoomOut(false)
        setNewRoomAddresses([]);
        setRemovedAddress([]);
      }
    });
  }*/

  useEffect(() => {
    errorUpdateRoom?.originalStatus === 409
      ? setErrorNotificationParams({
        isOpen: true,
        title: t("modal_error_plume:title"),
        content: t("modal_error_plume:text"),
      })
      : setIsErrorSubmit(isErrorSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorSubmit]);


  /* useEffect(() => {
     console.log("FFF", getValues(formFields.equipmentsCoordinates))
     if (selectedListItem == null) {
       if (scale <= 1 && getValues(formFields.equipmentsCoordinates))
         if (getValues(formFields.equipmentsCoordinates).length != 0)
           loadScheme()
 
     }
     console.log("Вывод:", scale)
   }, [scale]);
   */
  useEffect(() => {
    setIsErrorRequest(isErrorRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorRequest]);

  useEffect(() => {
    setIsErrorRequest(isErrorRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorRequest]);

  const handleDeleteRoom = () => {
   
    let adressLength = getValues(formFields.roomAddresses).length
    selectShape(null)
    if (selectedId + 1 > adressLength) {
      let arr = newRoomAddresses.filter((item, index) => index - getValues(formFields.roomAddresses).length !== selectedId);

      setNewRoomAddresses(arr);
    } else {
      let deleter = getValues(formFields.roomAddresses).filter((item, index) => index == selectedId )
      setdeletedAddress(prev => [...prev,...deleter])
      let arr = getValues(formFields.roomAddresses).filter((item, index) => index != selectedId );
      setValue(formFields.roomAddresses, arr);
    

    }
  };

  

  const handleListItemClick = (itemm, isDisabled) => (e) => {
    //if (isDisabled) return
    setScale(1)
    setstageposition({X:0,y:0})
    setselectedListItem((prev) => itemm)
    if(getValues(formFields.floor) != itemm.floorInfoId){
      setisselectedEvent(prev=>true)
      setValue(formFields.object, itemm.protectionObjectId)
      setmessageFloor(itemm.floorInfoId)
    } else{
      if (blinkingItem !== null && blinkingItem !== itemm?.id) {
        // Clear the previous blinking interval and effect
        setLineColor("")
        clearInterval(intervals[blinkingItem]);
      }
  
      if (blinkingItem !== itemm?.id) {
        startBlinking(itemm);
      } else {
        stopBlinking(itemm?.id);
      }
      
      window.innerWidth <= 600 && setopenEventSide(false)
      isSelectedSelect(itemm)
    }
    //setValue(formFields.object, itemm.protectedObjectId)
  }
  const startBlinking = (itemm) => {
    setBlinkingItem(itemm.id);

    // Start with setInterval
    const intervalId = setInterval(() => {

      setLineColor((prevColor) => ((prevColor === itemm?.borderColor || prevColor == "") ? 'transparent' : itemm?.borderColor));
    }, 600);

    // Save the interval ID to clear it later
    // It is important to prevent memory leaks
    setIntervals({ ...intervals, [itemm.id]: intervalId });
  };

  const stopBlinking = (id) => {
    setLineColor("")
    clearInterval(intervals[id]);
    setBlinkingItem(null);
  };

  const groupClick = (coordinates, roomid, scaleCanvas, height, width, isOneclick,isChangeOffset) => {
   // if (selectedListItem) return
   //console.log("")
    const evenIndexValues = coordinates.filter((_, index) => index % 2 === 0);  // Четные по индексу элементы
    const oddIndexValues = coordinates.filter((_, index) => index % 2 !== 0);  // Нечетные по индексу элементы

    const minEvenIndex = Math.min(...evenIndexValues);  // Минимальное значение четных по индексу элементов
    const maxEvenIndex = Math.max(...evenIndexValues);  // Максимальное значение четных по индексу элементов

    const minOddIndex = Math.min(...oddIndexValues);  // Минимальное значение нечетных по индексу элементов
    const maxOddIndex = (Math.max(...oddIndexValues));
    const diffX = (maxEvenIndex - minEvenIndex)
    const diffY = (maxOddIndex - minOddIndex)
    //const diffWidth = (width - minEvenIndex)
    getEquipmentsCoordinatesPost({id:floor,returnEquipments:true,returnRooms:true,roomAddressId:roomid,minPoint:{x:minEvenIndex / scaleCanvas.x,y:minOddIndex / scaleCanvas.y},maxPoint:{x:maxEvenIndex / scaleCanvas.x,y:maxOddIndex / scaleCanvas.y}}).then(({ data, isSuccess }) => {

      if (data) {
        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } =
          data || {};
        setFloorScheme(floorSchemeFileLink);

        const coordinates = equipmentCoordinates.map((item) => ({
          ...item,
          floorInfoId: floor,
        }));

      //  setselectedListItem(null)
        setisProtectionObjectIsInTest(false)
        setValue(formFields.equipmentsCoordinates, coordinates);
        setValue(formFields.roomAddresses, roomAddresses);
        if (roomid) {
        
          setzoomOut(true)
          let scaleStageX = 2;
          let scaleStageY = 2;
          let scaleStage = 2;
          let coorX = 0;
          let coorY = 0;
          const scaleAdresses = roomAddresses.filter((item) => item.id == roomid)[0].coordinates
          const scalecoordinates = scaleAdresses.filter((item) => item.order == 0)[0]
          scaleStageY = (((height - 10) / (maxOddIndex - minOddIndex)));
          scaleStageX = ((width - 10) / (maxEvenIndex - minEvenIndex));


          if (isOneclick) {
            if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= height / scaleStageY) || (diffX <= diffY && width < height)) {
              scaleStage = Math.max(scaleStageX,minScale)
            } else {
              scaleStage = Math.max(scaleStageY,minScale)
            }
            const diffWidth = (width - (diffX * scaleStage)) /2
            const diffHeight = (height - (diffY * scaleStage))/2
            if (diffX > diffY && (scaleStageY > scaleStageX || diffY * scaleStageY <= height / scaleStageY) || (diffX <= diffY && width < height)) {
              //coorX = (minEvenIndex - (diffWidth / scaleStage)) / scaleCanvas.x
              //coorY = (minOddIndex - (diffHeight / scaleStage)) / scaleCanvas.
              coorX = (minEvenIndex) / scaleCanvas.x
              coorY = (minOddIndex) / scaleCanvas.x
            } else {
              //coorX = (minEvenIndex - (diffWidth / scaleStage)) / scaleCanvas.x
             // coorY = (minOddIndex - (diffHeight / scaleStage)) / scaleCanvas.x
             coorX = (minEvenIndex) / scaleCanvas.x
              coorY = (minOddIndex) / scaleCanvas.x
            }

          } else {
            coorX = scalecoordinates.coordinateX
            coorY = scalecoordinates.coordinateY
          }
          setScale(scaleStage)
          const offsetX = coorX * scaleStage * scaleCanvas.y
          const offsetY = coorY * scaleStage * scaleCanvas.y
        if(isChangeOffset) {
          setstageposition((prev) => ({ x: 0 - offsetX, y: 0 - offsetY }));
        }
        
        }

        setIsDrawPoligon(false);

        setNewRoomAddresses([]);
        setRemovedAddress([]);
      }
    });
  }

  return (
    <Box>
      {openModalSetObject && (
        <ModalTableSetOnTo
          open={openModalSetObject}
          setOpen={setOpenModalSetObject}
          protectionObjectId={facilityId}
        />
      )}
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => { }}

        />
      )}
      <Box ref={$templatePage} className="template_page plume">
        <Box className="template_page-header">
          <Typography className="template_page-header-title">
            {t("title_page:plume_and_address")}
          </Typography>
        </Box>
        {showHeader && <Box className="plume-form_wrapper">
          <Box className="plume-form_wrapper-buttons" sx={{ flexWrap: "wrap" }}>
            <Button
              className="btn btn-outlined-primary magazine_events-select_container-btn"
              variant="outlined"
              onClick={handlestagingTO}
              disabled={turnOnEdit || addplume}
            >
              {t("button:staging_to_TO")}
            </Button>
           {/* <Box className="full_screen_icon-wrapper">
              {StartTO ?
                <PauseIcon
                  className="full_screen_icon"
                  sx={{
                    width: "30px",
                    height: "30px",
                    marginTop: "10px"
                  }}
                  onClick={handleTO}
                />
                :
                <PlayArrowIcon
                  className="full_screen_icon"
                  sx={{
                    width: "30px",
                    height: "30px",
                    marginTop: "10px"
                  }}
                  onClick={handleTO}
                />
              }
            </Box>
            */}
          </Box>
          <Box className="plume-form_wrapper-select_container">
            {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
              <FormItem
                key={id}
                name={name}
                control={control}
                label={label}
                type={type}

                disabled={isCanDisabled ? !facilityId || isErrorRequest || isDragabble : false}
                input={(props) => <SelectSimple {...props} />}
                {...rest}
              />
            ))}
          </Box>

        </Box>
        }
        {stagingTO ?
            (
              <Box className="plume-image_container" sx={{ display: "block" }}>
                <StagingTO
                  control={control}
                  floorScheme={floorScheme}
                  setValue={setValue}
                  openEventSide={openEventSide}
                  setopenEventSide={setopenEventSide}
                  lineColor={lineColor}
                  loadScheme={loadScheme}
                  searchObject={searchObject}
                  setsearchObject={setsearchObject}
                  setFloorScheme={setFloorScheme}
                  changeRoomAddresses={changeRoomAddresses}
                  isChangefloorScheme={isChangefloorScheme}
                  setmessageFloor={setmessageFloor}
                  setzoomOut={setzoomOut}
                  zoomOut={zoomOut}
                  zoomIn={zoomIn}
                  canvasRef={canvasRef}
                  isListClick={isListClick}
                  roomsColors={roomsColors}
                  imageRef={imageRef}
                  stageRef={stageRef}
                  minScale={minScale}
                  setminScale={setminScale}
                  setisListClick={setisListClick}
                  setzoomIn={setzoomIn}
                  groupClick={groupClick}
                  setopenEquipDialog={setopenEquipDialog}
                  setSelectedEquipId={setSelectedEquipId}
                  scaleCanvas={scale}
                  setisChangeFloor={setisChangeFloor}
                  isChangeFloor={isChangeFloor}
                  setScale={setScale}
                  getValues={getValues}
                  stageposition={stageposition}
                  setstageposition={setstageposition}
                  draggable={isDragabble}
                  equipGroup={true}
                  handleListItemClick={handleListItemClick}
                  selectedListItem={selectedListItem}
                  logsMessagess={logsMessagess}
                  isLoading={isLoading}
                  handleAddPlume={handleAddPlume}
                  handleEditPlume={handleEditPlume}
                  handlestagingTO={handlestagingTO}
                  handleTO={handleTO}
                  StartTO={StartTO}
                />
              </Box>
            )
            : (
              <Box></Box>
            )
        }


        <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
};
