import { Box, Typography } from "@mui/material";
import { addressFormConfig } from "./form-config";
import FormItem from "../../components/FormItem";
import React, { useMemo, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ADDRESSES_TYPES_INT } from "../MyOrganizations/constants";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { AddressInput } from "../../components/AddressInputOrg";

const AddressFormItem = ({ name, control, errors, indCheck, addressFromDadata, setaddressFromDadata, ...rest }) => {

  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const checkedIp = useMemo(() => fields.length === 1, [fields]);
  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    isChecked ? remove(1) : append({
      type: ADDRESSES_TYPES_INT.FACT,
      city: "",
      street: "",
      building: "",
      extension: "",
    });
  };
  useEffect(() => {
      if (indCheck && fields.length === 2) remove(1)
      if (!indCheck && fields.length === 1) append({
        type: ADDRESSES_TYPES_INT.FACT,
        city: "",
        street: "",
        building: "",
        extension: "",
      })
      if (fields.length > 2) remove(1)
    
  }, [indCheck]);
  console.log("addressFormConfig",indCheck)
  return (
    <>
      <FormControlLabel
        control={<Checkbox onChange={handleCheckbox} />}
        label={t("label:ip")}
        checked={checkedIp}
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "var(--IBM)",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "var(--standart)",
            lineHeight: "24px",
            color: "#212121",
          },
        }}
      />
      {fields.map((item, index) => (
        <Box key={item.id}>
          <Typography className="title-block" sx={{ margin: "15px 0" }}>
            {t(
              item.type === ADDRESSES_TYPES_INT.UR
                ? "label:ur_address"
                : "label:fact_address"
            )}
          </Typography>

          {addressFormConfig.map(
            ({ name: itemName, type, label, input, rest, rules }) => (
              itemName == "address" && addressFromDadata ?

                <FormItem
                  key={`${item.id}.${name}.${itemName}`}
                  control={control}
                  type={type}
                  errors={errors}
                  value={addressFromDadata[index]}
                  onChangeForOrg={setaddressFromDadata}
                  indexAdress={index}
                  label={label}
                  name={`${name}.${index}.${itemName}`}
                  input={(props) => (
                    <AddressInput {...props} />
                  )}
                 
                  {...rest}
                />
                :
                <FormItem
                  key={`${item.id}.${name}.${itemName}`}
                  control={control}
                  type={type}
                  errors={errors}
                  label={label}

                  name={`${name}.${index}.${itemName}`}
                  input={input}
               
                  {...rest}
                />
            )
          )}
        </Box>
      ))}
    </>
  )
}

export default AddressFormItem;