import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  createMuiTheme,
  ThemeProvider
} from '@mui/material/styles';
import { AppRoutes, NonAuthRoutes } from "./views/AppRoutes";
import { PATHS } from "./constants";
import "./styles/globalStyles/globalstyles.css";
import "./styles/variablesStyles/colors/colors.css";
import "./styles/variablesStyles/fonts/fonts.css";
import "./styles/variablesStyles/sizes/sizes.css";
import  AlertNewVersion  from "./views/AlertNewVersion";
import { ErrorFallback } from "./views/errorsPages/ErrorFallBack";
import { useLocation,useNavigate } from 'react-router-dom';
import PageNotFound from "./views/errorsPages/PageNotFound";
import { Can } from "./casl/Can";
import Layout from "./views/containers/Layout";
import { useAppSelector } from "./hooks/redux";
import { parseJsonToken } from "./helpers/helper";
import { APP_VERSION } from "./middleware/constants";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.users.token);
  const [permissions, setPermissions] = useState([]);
  const { roleId } = useAppSelector((state) => state.users.info);
  const [open, setOpen] = useState(false);

  function checkFacilitiesAvailability(array) {
    return array.includes("facilities");
  }

  const facilitiesAvailable = checkFacilitiesAvailability(permissions);
  useEffect(() => {
    const appVersion = localStorage.getItem('AppVersion');
    console.log("APP_VERSION",APP_VERSION)
    if(appVersion){
        if(appVersion != APP_VERSION){
       //setOpen(true)
        }
    }else{
      localStorage.setItem('AppVersion',APP_VERSION)
    }
  }, []);

  

  // Save the current location in the state object
  const saveCurrentLocation = () => {
    navigate(location.pathname, { state: { from: location } });
  };

  // Call saveCurrentLocation when the component mounts
  useEffect(() => {
    saveCurrentLocation();
  }, []);

  useEffect(() => {
 const storedPath = localStorage.getItem('currentPath');
    if (storedPath) {
      localStorage('currentPath'); // Remove the stored path once used
      navigate(storedPath);
    }
  }, [navigate]);
  useEffect(() => {
    token && parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token,setPermissions]);
  
  const protectedRoutes = AppRoutes.filter(
    (item) =>
      permissions.includes(item.permission) || item.permission === "profile"
  );
  const theme = createMuiTheme({
    typography: {
       fontFamily: "var(--IBM)",
       // Additional typography customizations can go here
    },
 });
  return (
    <ThemeProvider theme={theme}>
    <AlertNewVersion setOpen={setOpen} open={open} />
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Can I="manage" a="auth">
        <Layout>
          <Routes>
            {protectedRoutes.map((routeConfig) => (
              <Route
                key={Date.now()}
                exact={routeConfig.exact}
                path={routeConfig.path}
                element={routeConfig.component}
              />
            ))}
            <Route
              path={PATHS.SLASH}
              element={
                <Navigate
                  to={
                    roleId !== 4 && facilitiesAvailable
                      ? PATHS.OBJECTS_MAP
                      : PATHS.LIST_TASKS
                  }
                />
              }
            />
            <Route path={PATHS.ALL_ALIENS} element={<Navigate to={"/"} />} />
          </Routes>
        </Layout>
      </Can>

      <Can I="manage" a="notAuth">
        <Routes>
          {NonAuthRoutes.map((routeConfig) => (
            <Route
              key={Date.now()}
              exact={routeConfig.exact}
              path={routeConfig.path}
              element={routeConfig.component}
            />
          ))}
          <Route path={PATHS.ALL_ALIENS} element={<PageNotFound />} />
          <Route
            path={PATHS.SLASH}
            element={<Navigate to={PATHS.LOGIN_ROUTE} />}
          />
        </Routes>
      </Can>
    </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
